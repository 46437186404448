#experience {
  padding-left: 15%;
  min-height: 60vh;
  padding-bottom: 60px;
  margin-bottom: 40px;   
}

.job-description {
  padding-top: 24px;
  max-width: 650px;
  font-family: "NTR", sans-serif;
  font-size: 20px;
  margin-bottom: 20px; 
}

.job-content-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 20px;
}

.joblist-job-title {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.joblist-job-company {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  color: var(--green-bright);
  font-weight: bold;
}

.joblist-duration {
  font-family: "NTR", sans-serif;
  font-size: 18px;
  color: var(--slate);
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 25px;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-align: left;
  align-items: flex-start !important;
  font-family: "NTR", sans-serif;
}

.MuiButtonBase-root {
  outline: none !important;
  color: var(--lightest-slate) !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: var(--green-bright) !important;
}
.jss5 {
  background-color: var(--green-bright) !important;
}
.makeStyles-tabs-2 {
  border-right: 1px solid var(--lightest-navy) !important;
}
#experience .MuiTab-root {
  padding: 6px 20px;
}
.MuiButtonBase-root:focus {
  color: var(--green-bright) !important;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.job-description li::before {
  content: "▹    ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
}

.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: var(--slate);
}

.job-description {
  padding-top: 24px;
  max-width: 650px;
  font-family: "NTR", sans-serif;
  font-size: 20px;
}

.job-description a,
.job-description b {
  font-size: 19px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  #experience {
    padding: 20px;
  }

  #experience .section-header {
    padding-left: 0;    
    margin-left: -20px; 
  }

  .makeStyles-root-1 {
    flex-direction: column !important;
  }

  /* Fix tab styles */
  .MuiTabs-root {
    min-height: 48px !important;
  }

  .MuiTab-root {
    min-width: 50px !important;
    padding: 6px 12px !important;
  }

  /* Content container */
  #full-width-tabpanel-0,
  #full-width-tabpanel-1 {
    padding: 15px 0;
  }

  .job-description {
    padding-top: 15px;
  }

  .joblist-job-title,
  .joblist-job-company {
    font-size: 20px;
    line-height: 1.4;
  }

  /* Remove borders */
  .makeStyles-tabs-2 {
    border: none !important;
  }

  /* Fix box padding */
  .MuiBox-root {
    padding: 15px 0 !important;
  }
}