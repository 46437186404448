:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --back: #081930;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: var(--back) !important;
  min-height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  body {
    background-color: var(--back);
  }
}