#intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15%;
  padding-top: 5%;
  min-height: 100vh;
  margin-bottom: 100px;  
}

.Typist {
  color: var(--lightest-slate);
  text-align: center;
  font-size: 76px;
  margin-top: 80px; 
}

.intro-subtitle {
  margin-top: -20px; 
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-weight: 300;
  font-size: 48px;
}

.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 22px;
  max-width: 40%;
  text-align: justify;
  line-height: 1.3; 
  margin-top: 15px;
  z-index: 1; 
}

.Typist .Cursor--blinking {
  color: var(--green-bright);
  padding-left: 4px;
}

.intro-title {
  font-family: "NTR", sans-serif;
  font-size: 86px;
}
.intro-name {
  font-family: "NTR", sans-serif;
  color: var(--green-bright);
  font-weight: bold;
  font-size: 86px;
}

.intro-subtitle {
  margin-top: -36px;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-weight: 300;
  font-size: 48px;
}

.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 22px;
  max-width: 40%;
  text-align: justify;
  padding-bottom: 50px;
}

.intro-contact {
  font-size: 22px;
  font-weight: bolder;
  font-family: "NTR", sans-serif;
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid var(--green-bright);
  border-radius: 4px;
  margin-bottom: 40px;
}
.intro-contact:hover {
  background-color: var(--lightest-navy);
}

b {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  #intro {
    width: 100%;
    min-height: unset;
    padding: 40px 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  #intro .Typist {
    width: 100%;
    text-align: center;
    font-size: 40px;
    margin: 0;
    padding: 0;
  }

  #intro .intro-title, 
  #intro .intro-name {
    width: 100%;
    font-size: 40px;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  #intro .intro-subtitle {
    display: none;
  }

  #intro .intro-desc {
    width: 90%;
    max-width: 100%;
    font-size: 18px;
    text-align: center;
    margin: 20px auto;
    padding: 0;
    line-height: 1.5;
  }

  #intro .intro-contact {
    width: auto;
    font-size: 16px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 25px;
  }

  #intro .fade-in-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}